import { history } from "@Core/Redux/Store"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setCurrentUser } from "./Redux/Login"
import { useWhoamiMutation } from "./Services/Api"
  import Config from '@Config'
import Logger from "@Common/Utils/Logger"

export const useAuthentication = (condition, deps = []) => {
  const dispatch = useDispatch()
  const [whoami] = useWhoamiMutation()
  const [isComplete, setIsComplete] = useState(false)


  const fetchCurrentUser = async () => {
    Logger.debug('Calling whoami')
    try {
      const response = await whoami().unwrap()
      dispatch(setCurrentUser(response.user))
      Logger.debug('Whoami success')
    } catch (err) {
      history.push(Config.urls.login)
      Logger.debug('Whoami error')
    }
    setIsComplete(true)
  }

  useEffect(() => {
    if (condition) setTimeout(fetchCurrentUser, 1000)
  }, deps)

  return {
    isComplete
  }
}
