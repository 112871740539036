import { api } from '@Core/Services/Api';

const prefix = 'server'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    servers: builder.query({
      query: () => ({
        url: `${prefix}`,
      }),
      providesTags: [{ type: 'Server', id: 'LIST' }],
    }),
    server: builder.query({
      query: (id) => ({
        url: `${prefix}/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'Server', id: id }],
    }),
    createServer: builder.mutation({
      query: (server) => ({
        url: `${prefix}`,
        body: server,
        method: 'POST',
      }),
      invalidatesTags: ['Server'],
    }),
    updateServer: builder.mutation({
      query: ({ id, server }) => ({
        url: `${prefix}/${id}`,
        body: server,
        method: 'PUT',
      }),
      invalidatesTags: ['Server'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useServersQuery,
  useServerQuery,
  useCreateServerMutation,
  useUpdateServerMutation,
} = extendedApi
