import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from '@Config'
import { logout, setAccessToken } from '@Auth/Redux/Login'
import storageFactory from '@Common/Services/Storage'
import Logger from '@Common/Utils/Logger'
import * as R from 'ramda'

const Storage = storageFactory()

let refreshTokenPromise
let refreshLocked = false // lock request retry while refreshing

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBasePath,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().auth.login.accessToken
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  const refreshToken = Storage.get('refreshToken', false)
  // eslint-disable-next-line no-constant-condition
  if (result.error && result.error.status === 401 && refreshToken) {
    Logger.debug('API 401 detected', args)
    if (!refreshLocked) {
      Logger.debug('API no one is refreshing -> acquiring lock and actually refreshing now', args)
      refreshLocked = true // acquire lock
      let resolveRefreshTokenPromise
      refreshTokenPromise = new Promise((resolve) => {
        resolveRefreshTokenPromise = resolve
      })
      const credentials = await baseQuery(
        {
          url: 'auth/token/refresh',
          method: 'POST',
          body: { refreshToken },
        },
        api,
        extraOptions,
      )

      if (credentials.data) {
        Logger.debug('API token refreshed', args, credentials)
        api.dispatch(setAccessToken(credentials.data))
        Logger.debug('API credentials set', args)
        Logger.debug('API releasing refresh lock', args)
        resolveRefreshTokenPromise()
        refreshLocked = false
      } else {
        resolveRefreshTokenPromise()
        refreshLocked = false
        api.dispatch(logout())
        window.location.assign(config.urls.login)
        return result
      }
    }

    Logger.debug('API would like to run again request', args)
    if (refreshLocked) {
      Logger.debug('API wait, someone is refreshing token', args)
      await refreshTokenPromise
      Logger.debug('API wait for other refreshing: DONE', args)
    }

    Logger.debug('API actually running again request', args)
    // retry the initial query
    result = await baseQuery(args, api, extraOptions)
    Logger.debug('API request retry: DONE', args)
  }

  return result
}

// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'AuthenticatedUser',
    'Domain',
    'Server',
    'User',
  ],
  endpoints: () => ({}),
})

export const apiQueryString = (qs) => {
  const items = Object.keys(qs)
    .filter(R.compose(R.not, R.either(R.isEmpty, R.isNil), R.flip(R.prop)(qs)))
    .map((k) => `${k}=${encodeURIComponent(qs[k])}`)

  return items.length ? '&' + items.join('&') : ''
}
