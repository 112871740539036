import PropTypes from 'prop-types'
import { Select } from "@chakra-ui/react"
import { useServersQuery } from '@Servers/Services/Api'
import { compose, defaultTo, find, flip, ifElse, path, propEq } from 'ramda'

const ServerSelect = ({ value, onChange, required }) => {
  const { data } = useServersQuery()

  const handleChange = ifElse(
    path(['target', 'value']),
    compose(onChange, flip(find)(data), propEq('id'), path(['target', 'value'])),
    compose(onChange, path(['target', 'value'])), 
  )

  return (
    <Select value={value} onChange={handleChange}>
      {!required && (<option key='null' value={''} />)} 
      {defaultTo([], data).map(server => (
        <option key={server.id} value={server.id}>{ server.name}</option>
      ))}
    </Select>
  )
}

ServerSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
}

export default ServerSelect
