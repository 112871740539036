import PropTypes from 'prop-types'
import { Box, Center, Flex, Heading, Icon, Link, Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'
import { withLoader } from '@Common/Utils/HOF'
import { makePath } from '@Config'
import ServersRequestTimeChart from '@Servers/Components/ServersRequestTimeChart'
import { ServerType } from '@Servers/Models/Server'
import { defaultTo, pathEq } from 'ramda'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline, IoCheckmarkCircleOutline, IoServerOutline } from 'react-icons/io5'
import { Link as RLink } from 'react-router-dom'

const ServersWidget = ({ servers, isFetching }) => {
  const { t } = useTranslation()

  return (
    <Box flexGrow={1}>
      <Flex direction="row">
        <Center p="1rem" background={'gray.200'} color="black">
          <StatGroup flexDirection="column">
            <Stat width='100%'>
              <StatLabel fontSize={'1.4rem'} display="flex" alignItems={'center'} gap=".5rem">
                <Icon as={IoServerOutline} />
                {t('ui.Total')}
              </StatLabel>
              <StatNumber textAlign={'center'} fontSize={'3rem'}>
                {defaultTo([], servers).length}
              </StatNumber>
            </Stat>

            <Stat width='100%'>
              <StatLabel fontSize={'1.4rem'} display="flex" alignItems={'center'} gap=".5rem">
                <Icon as={IoCheckmarkCircleOutline} color="green.400" />
                {t('ui.Ok')}
              </StatLabel>
              <StatNumber textAlign={'center'} fontSize={'3rem'} color={'green.400'}>
                {defaultTo([], servers).filter(pathEq(['status', 'error'], false)).length}
              </StatNumber>
            </Stat>

            <Stat width='100%'>
              <StatLabel fontSize={'1.4rem'} display="flex" alignItems={'center'} gap=".5rem">
                <Icon as={IoAlertCircleOutline} color="red" />
                {t('ui.Ko')}
              </StatLabel>
              <StatNumber textAlign={'center'} fontSize={'3rem'} color={'red.400'}>
                {defaultTo([], servers).filter(pathEq(['status', 'error'], true)).length}
              </StatNumber>
            </Stat>
          </StatGroup>
        </Center>
        <Box p="1rem" flexGrow={2} background={'blue.50'}>
          <Flex direction="row" justify="space-between" p=".5rem .9rem">
            <Heading async="h2" size="lg">
              <Link as={RLink} to={makePath('servers.list')}>
                {t('servers:ui.Servers')}
              </Link>
            </Heading>
          </Flex>
          {withLoader(
            () => (
              <ServersRequestTimeChart servers={servers} />
            ),
            isFetching,
          )}
        </Box>
      </Flex>
    </Box>
  )
}

ServersWidget.propTypes = {
  servers: PropTypes.arrayOf(ServerType),
  isFetching: PropTypes.bool,
}

export default ServersWidget
