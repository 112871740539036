/* eslint-disable react/no-children-prop */
import {
  Link,
  Box,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import { toast } from '@Common/Components/Toast'
import { responseTextError } from '@Common/Utils/Api'
import Logger from '@Common/Utils/Logger'
import { makePath } from '@Config'
import { always, anyPass, compose, defaultTo, includes, pipe, prop } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IoAddOutline,
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
  IoPencilOutline,
  IoSearchOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import { Link as ReactRouterLink } from 'react-router-dom'
import BaseLayout from '@Common/Layouts/BaseLayout'
import {
  useCreateDomainMutation,
  useDeleteDomainMutation,
  useDomainsQuery,
  useUpdateDomainMutation,
} from '@Domains/Services/Api'
import DomainForm from '@Domains/Forms/DomainForm'
import { useDeleteConfirm } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import { nanoSecondsToMilliSeconds } from '@Common/Utils/Datetime'

const DomainListView = () => {
  const { t } = useTranslation()
  const [createDomain] = useCreateDomainMutation()
  const [updateDomain] = useUpdateDomainMutation()
  const [deleteDomain] = useDeleteDomainMutation()

  const [form, setForm] = useState({ isOpen: false, domain: null })
  const [filter, setFilter] = useState('')
  const handleAddDomain = () => setForm({ isOpen: true, domain: null })
  const handleUpdateDomain = (domain) => () => setForm({ isOpen: true, domain })
  const handleCloseForm = () => setForm({ isOpen: false, domain: null })

  const { data } = useDomainsQuery()

  const handleSubmit = async (fields) => {
    const update = form.domain?.id
    const data = { ...fields }
    if (fields.serverId) data.server = { id: fields.serverId, name: fields.serverName }
    try {
      update ? await updateDomain({ id: form.domain.id, domain: data }).unwrap() : await createDomain(data).unwrap()
      toast({
        title: t(`domains:ui.${update ? 'Edit' : 'Add'}Domain`),
        description: t('ui.OperationSuccessfull'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      Logger.error(`${update ? 'Update' : 'Create'} domain error`, err)
      toast({
        title: t(`domains:ui.${update ? 'Edit' : 'Add'}Domain`),
        description: t('ui.OperationError', { error: responseTextError(err) }),
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    }
    handleCloseForm()
  }

  const handleDelete = async (domain) => {
    try {
      await deleteDomain(domain.id).unwrap()
      toast({
        title: t('domains:ui.DeleteDomain', { name: domain.name }),
        description: t('ui.OperationSuccessfull'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      Logger.error('Delete domain error', err)
      toast({
        title: t('domains:ui.DeleteDomain', { name: domain.name }),
        description: t('ui.OperationError', { error: responseTextError(err) }),
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    }
  }

  const { ConfirmDialog, handleOpen } = useDeleteConfirm(handleDelete)
  const handleDeleteConfirm = (domain) =>
    compose(handleOpen, always({ payload: domain, title: t('domains:ui.DeleteDomain', { name: domain.name }) }))

  return (
    <BaseLayout>
      <Box p="2rem 1rem 1rem">
        <Flex direction="row" justify="space-between" p="0 .9rem 1rem">
          <Heading async="h2" size="lg">
            {t('domains:ui.Domains')}
          </Heading>
          <IconButton isRound onClick={handleAddDomain}>
            <IoAddOutline />
          </IconButton>
        </Flex>
        <Box w={'200px'} m=".5rem 0 1.5rem 1rem">
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<IoSearchOutline color="gray.300" />} />
            <Input value={filter} onChange={setStateFromEvent(setFilter)} placeholder={t('actions.Search')} />
          </InputGroup>
        </Box>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t('domains:fields.name')}</Th>
              <Th>{t('domains:fields.registrant')}</Th>
              <Th>{t('domains:fields.registrar')}</Th>
              <Th>{t('domains:fields.ip')}</Th>
              <Th>{t('domains:fields.server')}</Th>
              <Th>{t('domains:fields.technology')}</Th>
              <Th>{t('domains:fields.framework')}</Th>
              <Th>{t('domains:fields.requestTime')}</Th>
              <Th>{t('domains:fields.status')}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {defaultTo([], data)
              .filter(anyPass([pipe(prop('name'), includes(filter))]))
              .map((domain) => (
                <Tr key={domain.id}>
                  <Td>
                    <Link as={ReactRouterLink} color="red" to={makePath('domains.detail', { domainId: domain.id })}>
                      {domain.name}
                    </Link>
                  </Td>
                  <Td>{domain.registrant}</Td>
                  <Td>{domain.registrar}</Td>
                  <Td>{domain.ip}</Td>
                  <Td>{domain.server?.name}</Td>
                  <Td>{domain.technology}</Td>
                  <Td>{domain.framework}</Td>
                  <Td>
                    {domain.urlStatus.error ? '' : <span>{nanoSecondsToMilliSeconds(domain.urlStatus.total)} ms</span>}
                  </Td>
                  <Td>
                    {domain.urlStatus.error ? (
                      <Tooltip label={domain.urlStatus.errorDescription}>
                        <span>
                          <Icon as={IoAlertCircleOutline} color="red" w={8} h={8} />
                        </span>
                      </Tooltip>
                    ) : (
                      <Icon as={IoCheckmarkCircleOutline} color="green.400" w={8} h={8} />
                    )}
                  </Td>
                  <Td textAlign="end">
                    <IconButton isRound onClick={handleUpdateDomain(domain)} mr=".5rem">
                      <IoPencilOutline />
                    </IconButton>
                    <IconButton isRound onClick={handleDeleteConfirm(domain)}>
                      <IoTrashOutline />
                    </IconButton>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {form.isOpen && <DomainForm domain={form.domain} onClose={handleCloseForm} onSubmit={handleSubmit} />}
        {ConfirmDialog}
      </Box>
    </BaseLayout>
  )
}

export default DomainListView
