import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// namespaces
import i18nCommon from './Common/Locales/i18n.json'
import i18nAuth from './Auth/Locales/i18n.json'
import i18nDashboard from './Dashboard/Locales/i18n.json'
import i18nServers from './Servers/Locales/i18n.json'
import i18nDomains from './Domains/Locales/i18n.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  it: {
    common: i18nCommon,
    auth: i18nAuth,
    dashboard: i18nDashboard,
    servers: i18nServers,
    domains: i18nDomains,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // language to use, you can use the i18n.changeLanguage function
    // to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    lng: 'it',
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // react already saves from xss
    },
  })

export default i18n
