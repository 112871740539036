import PropTypes from 'prop-types'
export const DomainServerType = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
})

export const UrlStatusType = PropTypes.shape({
    connect: PropTypes.number,
    contentTransfer: PropTypes.number,
    dnsLookup: PropTypes.number,
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    nameLookup: PropTypes.number,
    preTransfer: PropTypes.number,
    serverProcessing: PropTypes.number,
    startTransfer: PropTypes.number,
    tcpConnection: PropTypes.number,
    tlsHandshake: PropTypes.number,
    total: PropTypes.number,
    updated: PropTypes.number,
})

export const DomainType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlStatus: UrlStatusType,
  registrant: PropTypes.string.isRequired,
  registrar: PropTypes.string.isRequired,
  registrantInfo: PropTypes.string,
  ip: PropTypes.string.isRequired,
  server: DomainServerType,
  notes: PropTypes.string,
  created: PropTypes.number,
  updated: PropTypes.number,
})
