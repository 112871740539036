import { Box, SimpleGrid } from '@chakra-ui/react'
import BaseLayout from '@Common/Layouts/BaseLayout'
import ServersWidget from '@Dashboard/Components/ServersWidget'
import DomainsWidget from '@Domains/Components/DomainsWidget'
import FrameworksChart from '@Domains/Components/FrameworksChart'
import TechnologiesChart from '@Domains/Components/TechnologiesChart'
import { useDomainsQuery } from '@Domains/Services/Api'
import { useServersQuery } from '@Servers/Services/Api'
import { defaultTo } from 'ramda'

const DashboardView = () => {
  const { data: servers, isFetching: isFetchingServers } = useServersQuery()
  const { data: domains, isFetching: isFetchingDomains } = useDomainsQuery()

  return (
    <BaseLayout>
      <Box p={5} gap="1rem">
        <SimpleGrid columns={2} spacing={2}>
          <Box>
            <ServersWidget servers={defaultTo([], servers)} isFetching={isFetchingServers} />
          </Box>
          <Box>
            <DomainsWidget domains={defaultTo([], domains)} isFetching={isFetchingDomains} />
          </Box>
          <Box>
            <TechnologiesChart domains={defaultTo([], domains)} isFetching={isFetchingDomains} />
          </Box>
          <Box>
            <FrameworksChart domains={defaultTo([], domains)} isFetching={isFetchingDomains} />
          </Box>
        </SimpleGrid>
      </Box>
    </BaseLayout>
  )
}

export default DashboardView
