import { createSlice } from '@reduxjs/toolkit'
import storageFactory from '@Common/Services/Storage'
import Logger from '@Common/Utils/Logger'

const Storage = storageFactory()

const slice = createSlice({
  name: 'login',
  initialState: { accessToken: Storage.get('accessToken'), user: null },
  reducers: {
    setAccessToken: (state, { payload: { accessToken } }) => {
      state.accessToken = accessToken
      Storage.save('accessToken', accessToken)
    },
    setCurrentUser: (state, { payload: user }) => {
      state.user = user
    },
    logout: (state) => {
      Logger.info('Logout from application')
      Storage.remove('accessToken')
      Storage.remove('refreshToken')
      state.accessToken = null
      state.user = null
    },
  },
})

export const { setAccessToken, setCurrentUser, logout } = slice.actions

export default slice.reducer

export const selectAccessToken = (state) => state.auth.login.accessToken
export const selectCurrentUser = (state) => state.auth.login.user
