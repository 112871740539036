import PropTypes from 'prop-types'
import { Alert, AlertIcon, Box, FormHelperText, Input, Select } from '@chakra-ui/react'
import Modal from '@Common/Components/Modal'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useForm } from '@Common/Utils/Hooks'
import { useTranslation } from 'react-i18next'
import { checkRequired } from '@Common/Utils/Validation'
import { always, compose, defaultTo, ifElse, isEmpty, pipe, prop, tap } from 'ramda'
import { UserType } from '@Auth/Models/User'
import Field from '@Common/Components/Field'
import i18next from 'i18next'

export const validate = (user) => (fields) => {
  const errors = {}
  const required = ['email', 'role']
  if (!user?.id) {
    required.push('password')
    required.push('passwordRepeat')
  }
  checkRequired(errors, required, fields)
  if (fields.password && fields.password !== fields.passwordRepeat) {
    errors.password = i18next.t('auth:validation.passwordDoesntMatch')
  }
  if (fields.password && fields.password.length < 8) {
    errors.password = i18next.t('auth:validation.passwordTooShort')
  }
  return { success: isEmpty(errors), errors }
}

const UserForm = ({ onClose, onSubmit, user }) => {
  const { t } = useTranslation()
  const { fields, setField, errors, setErrors, clearErrors } = useForm({
    email: defaultTo('', user?.email),
    role: defaultTo('', user?.role),
  })

  const handleSubmit = pipe(
    always(fields),
    validate(user),
    ifElse(prop('success'), compose(onSubmit, always(fields), tap(clearErrors)), compose(setErrors, prop('errors'))),
  )

  return (
    <Modal
      isOpen
      onClose={onClose}
      title={t(`auth:ui.${user ? 'Edit' : 'Add'}User`)}
      onSubmit={handleSubmit}
      submitLabel={t('actions.Save')}
    >
      <Box>
        <Field isRequired error={errors.email} label={t('auth:fields.email')}>
          <Input name="email" type="email" value={fields.email} onChange={setStateFromEvent(setField('email', ''))} />
        </Field>
        <Field isRequired error={errors.role} label={t('auth:fields.role')}>
          <Select value={fields.role} onChange={setStateFromEvent(setField('role'))}>
            <option value={'admin'}>{t('auth:roles.admin')}</option>
            <option value={'superadmin'}>{t('auth:roles.superadmin')}</option>
          </Select>
        </Field>
        {user?.id && (
          <Alert status='info' mb='1rem'>
            <AlertIcon />
            {t('auth:ui.FillBelowFieldsToChangePassword')}
          </Alert>
        )}
        <Field isRequired={!user?.id} error={errors.password} label={t('auth:fields.password')}>
          <Input name="password" type="password" value={fields.password} onChange={setStateFromEvent(setField('password', ''))} />
          <FormHelperText>{t('auth:helperTexts.password')}</FormHelperText>
        </Field>
        <Field isRequired={!user?.id} error={errors.passwordRepeat} label={t('auth:fields.passwordRepeat')}>
          <Input name="passwordRepeat" type="password" value={fields.passwordRepeat} onChange={setStateFromEvent(setField('passwordRepeat', ''))} />
        </Field>
      </Box>
    </Modal>
  )
}

UserForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: UserType,
}

export default UserForm
