import PropTypes from 'prop-types'
export const ServerStatusType = PropTypes.shape({
    connect: PropTypes.number,
    contentTransfer: PropTypes.number,
    dnsLookup: PropTypes.number,
    error: PropTypes.bool,
    errorDescription: PropTypes.string,
    nameLookup: PropTypes.number,
    preTransfer: PropTypes.number,
    serverProcessing: PropTypes.number,
    startTransfer: PropTypes.number,
    tcpConnection: PropTypes.number,
    tlsHandshake: PropTypes.number,
    total: PropTypes.number,
    updated: PropTypes.number,
})

const ServerStatsType = PropTypes.shape({
  AvailableMemory: PropTypes.number,
  availableMemoryPerc: PropTypes.number,
  usedMemoryPerc: PropTypes.number,
  totalMemory: PropTypes.number,
  cpuCount: PropTypes.number,
  cpuLoadPerc: PropTypes.number,
  created: PropTypes.number,
})

export const ServerType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  ip: PropTypes.string.isRequired,
  os: PropTypes.string.isRequired,
  kernel: PropTypes.string.isRequired,
  cpu: PropTypes.string.isRequired,
  ram: PropTypes.string.isRequired,
  status: ServerStatusType,
  lastError: ServerStatusType,
  stats: PropTypes.arrayOf(ServerStatsType),
  created: PropTypes.number,
  updated: PropTypes.number,
})
