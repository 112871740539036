import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '@Core/App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './Core/Redux/Store'
import Logger from '@Common/Utils/Logger'
import './i18n'

Logger.info('Initializing systems management application')
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
