import PropTypes from 'prop-types'
import { Box, Input } from '@chakra-ui/react'
import Modal from '@Common/Components/Modal'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useForm } from '@Common/Utils/Hooks'
import { useTranslation } from 'react-i18next'
import { checkRequired } from '@Common/Utils/Validation'
import { always, compose, defaultTo, ifElse, includes, isEmpty, pipe, prop, tap } from 'ramda'
import { ServerType } from '@Servers/Models/Server'
import Field from '@Common/Components/Field'

export const validate = (fields) => {
  const errors = {}
  checkRequired(errors, ['name', 'ip', 'os', 'kernel', 'cpu', 'ram'], fields)
  return { success: isEmpty(errors), errors }
}

const ServerForm = ({ onClose, onSubmit, server }) => {
  const { t } = useTranslation()
  const { fields, setField, errors, setErrors, clearErrors } = useForm({
    name: defaultTo('', server?.name),
    description: defaultTo('', server?.description),
    ip: defaultTo('', server?.ip),
    os: defaultTo('', server?.os),
    kernel: defaultTo('', server?.kernel),
    cpu: defaultTo('', server?.cpu),
    ram: defaultTo('', server?.ram),
    domain: defaultTo('', server?.domain),
    healthCheckUrl: defaultTo('', server?.healthCheckUrl),
    fetchStatsUrl: defaultTo('', server?.fetchStatsUrl),
  })

  const handleSubmit = pipe(
    always(fields),
    validate,
    ifElse(prop('success'), compose(onSubmit, always(fields), tap(clearErrors)), compose(setErrors, prop('errors'))),
  )

  const formFields = ['name', 'description', 'ip', 'os', 'kernel', 'cpu', 'ram', 'domain', 'healthCheckUrl', 'fetchStatsUrl']
  const requiredFields = ['name', 'ip', 'os', 'kernel', 'cpu', 'ram']

  return (
    <Modal
      isOpen
      onClose={onClose}
      title={t(`servers:ui.${server ? 'Edit' : 'Add'}Server`)}
      onSubmit={handleSubmit}
      submitLabel={t('actions.Save')}
    >
      <Box>
        {formFields.map((field) => (
          <Field
            key={field}
            isRequired={includes(field, requiredFields)}
            error={errors[field]}
            label={t(`servers:fields.${field}`)}
          >
            <Input name="name" value={fields[field]} onChange={setStateFromEvent(setField(field, ''))} />
          </Field>
        ))}
      </Box>
    </Modal>
  )
}

ServerForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  server: ServerType,
}

export default ServerForm
