import { Alert, AlertIcon, Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { humanizeSize } from '@Common/Utils/Files'
import { round } from '@Common/Utils/Numbers'
import { gt } from '@Common/Utils/Ramda'
import { ServerType } from '@Servers/Models/Server'
import dayjs from 'dayjs'
import { defaultTo, head, length, pipe, prop } from 'ramda'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'
import ServerStatsChart from './ServerStatsChart'

const ServerStats = ({ server }) => {
  const { t } = useTranslation()

  const hasStats = pipe(prop('stats'), defaultTo([]), length, gt(0))(server)
  const recentStats = pipe(prop('stats'), defaultTo([]), head)(server)

  return (
    <div>
      <Heading display="flex" justifyContent="space-between" mb="1rem">
        {t('servers:ui.Statistics')}
        {hasStats && (
          <Flex
            color={server.status.error ? 'red' : 'green.400'}
            fontSize={'1.5rem'}
            alignItems="center"
            justifyContent="center"
            gap="1rem"
          >
            <IoCalendarOutline />
            <Text fontSize={'1rem'} pt={'.1rem'}>
              {dayjs(recentStats.created * 1e3).format('YYYY-MM-DD HH:mm:ss')}
            </Text>
          </Flex>
        )}
      </Heading>
      {!hasStats && (
        <Alert status='info' mb="1rem">
          <AlertIcon />
          {t('servers:ui:StatisticsNotAvailable')}
        </Alert>
      )}
      {hasStats && (
        <Table w={'100%'} mb="2rem">
          <Thead>
            <Tr>
              <Th>{t('servers:ui.AvailableMemory')}</Th>
              <Th>{t('servers:ui.UsedMemory')}</Th>
              <Th>{t('servers:ui.TotalMemory')}</Th>
              <Th>{t('servers:ui.CpuCount')}</Th>
              <Th>{t('servers:ui.CpuLoad')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{round(recentStats.availableMemoryPerc, 1)} %</Td>
              <Td>{round(recentStats.usedMemoryPerc, 1)} %</Td>
              <Td>{humanizeSize(recentStats.totalMemory)}</Td>
              <Td>{round(recentStats.cpuCount, 1)}</Td>
              <Td>{round(recentStats.cpuLoadPerc, 1)} %</Td>
            </Tr>
          </Tbody>
        </Table>
      )}
      {hasStats && (
        <ServerStatsChart stats={server.stats} />
      )}
    </div>
  )
}

ServerStats.propTypes = {
  server: ServerType,
}

export default ServerStats
