import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { prop, sortBy } from 'ramda'
import dayjs from 'dayjs'
import config from '@Config'
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
var utc = require('dayjs/plugin/utc')

dayjs.extend(utc)
dayjs.extend(timezone)

const ServerStatsChart = ({ stats }) => {
  const sortedStats = sortBy(prop('created'), stats)
  const options = {
    chart: {
      zooming: {
        type: 'x'
      }
    },
    time: {
      timezone: 'Europe/Rome',
      moment: dayjs,
    },
    credits: {
        enabled: false
    },
    title: {
      text: 'Memory usage and CPU load',
      style: {
        display: 'none',
      },
    },
    colors: config.ui.chartColors,
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date',
      },
    },
    yAxis: [
      {
        title: {
          text: 'RAM usage %',
        },
        min: 0,
        max: 100,
      },
      {
        title: {
          text: 'CPU load %',
        },
        min: 0,
        max: 100,
        opposite: true,
      },
    ],
    series: [
      {
        name: 'RAM usage',
        data: sortedStats.map((s) => [dayjs(s.created).valueOf() * 1e3, s.usedMemoryPerc]),
        yAxis: 0,
      },
      {
        name: 'CPU load',
        data: sortedStats.map((s) => [dayjs(s.created).valueOf() * 1e3, s.cpuLoadPerc]),
        yAxis: 1,
      },
    ],
  }
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
ServerStatsChart.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      usedMemoryPerc: PropTypes.number,
      cpuLoadPerc: PropTypes.number,
    }),
  ),
}
export default ServerStatsChart
