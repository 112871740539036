import Logo from '@Assets/Images/log8.jpg'
import { Center, Icon, Image, Link } from '@chakra-ui/react'
import { Link as RLink, useLocation } from 'react-router-dom'
import config, { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import { IoAlbumsOutline, IoHomeOutline, IoPersonOutline, IoServerOutline } from 'react-icons/io5'

const Sidebar = () => {
  const goHome = () => history.push(config.urls.home)
  const location = useLocation()
  return (
    <nav>
      <Image cursor="pointer" onClick={goHome} src={Logo} alt="logo" width="100%" />
      <Center
        py={'1rem'}
        alignItems={'center'}
        justifyContent={'center'}
        w="100%"
        bg={location.pathname === makePath('home') ? 'yellow.500' : undefined}
      >
        <Link as={RLink} to={makePath('home')} lineHeight={0}>
          <Icon as={IoHomeOutline} color="white" w={8} h={8} />
        </Link>
      </Center>
      <Center
        py={'1rem'}
        alignItems={'center'}
        justifyContent={'center'}
        w="100%"
        bg={location.pathname.includes(makePath('servers.list')) ? 'yellow.500' : undefined}
      >
        <Link as={RLink} to={makePath('servers.list')} lineHeight={0}>
          <Icon as={IoServerOutline} color="white" w={8} h={8} />
        </Link>
      </Center>
      <Center
        py={'1rem'}
        alignItems={'center'}
        justifyContent={'center'}
        w="100%"
        bg={location.pathname.includes(makePath('domains.list')) ? 'yellow.500' : undefined}
      >
        <Link as={RLink} to={makePath('domains.list')} lineHeight={0}>
          <Icon as={IoAlbumsOutline} color="white" w={8} h={8} />
        </Link>
      </Center>
      <Center
        py={'1rem'}
        alignItems={'center'}
        justifyContent={'center'}
        w="100%"
        bg={location.pathname.includes(makePath('users')) ? 'yellow.500' : undefined}
      >
        <Link as={RLink} to={makePath('users')} lineHeight={0}>
          <Icon as={IoPersonOutline} color="white" w={8} h={8} />
        </Link>
      </Center>
    </nav>
  )
}

export default Sidebar
