import React from 'react'
import { Route, Routes } from 'react-router-dom'
import config from '@Config'

import DomainDetailView from '@Domains/Views/DomainDetailView'
import DomainListView from '@Domains/Views/DomainListView'

/**
 * Servers section router
 */
export const DomainsRouter = () => {
  return (
    <Routes>
      <Route path={config.urls.domains.detail} element={<DomainDetailView />} />
      <Route path={config.urls.domains.list} element={<DomainListView />} />
    </Routes>
  )
}

export default DomainsRouter
