import PropTypes from 'prop-types'
import { Box, Checkbox, Input, Textarea } from '@chakra-ui/react'
import Modal from '@Common/Components/Modal'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useForm } from '@Common/Utils/Hooks'
import { useTranslation } from 'react-i18next'
import { checkRequired } from '@Common/Utils/Validation'
import { always, compose, defaultTo, identity, ifElse, includes, isEmpty, path, pick, pipe, prop, tap } from 'ramda'
import Field from '@Common/Components/Field'
import { DomainType } from '@Domains/Models/Domain'
import ServerSelect from '@Servers/Components/ServerSelect'

export const validate = (fields) => {
  const errors = {}
  checkRequired(errors, ['name', 'registrant', 'registrar', 'ip'], fields)
  return { success: isEmpty(errors), errors }
}

const DomainForm = ({ onClose, onSubmit, domain }) => {
  const { t } = useTranslation()
  const { fields, setField, setFields, errors, setErrors, clearErrors } = useForm({
    name: defaultTo('', domain?.name),
    url: defaultTo('', domain?.url),
    registrant: defaultTo('', domain?.registrant),
    registrar: defaultTo('', domain?.registrar),
    registrantInfo: defaultTo('', domain?.registrantInfo),
    ip: defaultTo('', domain?.ip),
    serverName: defaultTo('', domain?.server?.name),
    serverId: defaultTo('', domain?.server?.id),
    technology: defaultTo('', domain?.technology),
    framework: defaultTo('', domain?.framework),
    notes: defaultTo('', domain?.notes),
    includeInDashboardChart: defaultTo(false, domain?.includeInDashboardChart),
  })

  const handleSubmit = pipe(
    always(fields),
    validate,
    ifElse(prop('success'), compose(onSubmit, always(fields), tap(clearErrors)), compose(setErrors, prop('errors'))),
  )

  const formFields = [
    'name',
    'url',
    'registrant',
    'registrar',
    'registrantInfo',
    'ip',
    'server',
    'technology',
    'framework',
    'notes',
    'includeInDashboardChart',
  ]
  const textareaFields = ['registrantInfo', 'notes']
  const requiredFields = ['name', 'registrant', 'registrar', 'ip']

  const handleChangeServer = ifElse(
    identity,
    compose(({ id, name }) => setFields({ ...fields, serverId: id, serverName: name }), pick(['id', 'name'])),
    compose(setFields, always({ ...fields, serverId: '', serverName: '' })),
  )

  return (
    <Modal
      isOpen
      onClose={onClose}
      title={t(`domains:ui.${domain ? 'Edit' : 'Add'}Domain`)}
      onSubmit={handleSubmit}
      submitLabel={t('actions.Save')}
    >
      <Box>
        {formFields.map((field) => {
          if (field === 'server') {
            return (
              <Field
                key={field}
                isRequired={includes(field, requiredFields)}
                error={errors[field]}
                label={t(`domains:fields.${field}`)}
              >
                <ServerSelect value={fields.serverId} onChange={handleChangeServer} />
              </Field>
            )
          } else if (field === 'includeInDashboardChart') {
            return (
              <Field key={field}>
                <Checkbox
                  key={field}
                  colorScheme="green"
                  isChecked={fields.includeInDashboardChart}
                  onChange={compose(setField('includeInDashboardChart'), path(['target', 'checked']))}
                >
                  {t('domains:fields.includeInDashboardChart')}
                </Checkbox>
              </Field>
            )
          } else {
            const InputComponent = textareaFields.indexOf(field) === -1 ? Input : Textarea
            return (
              <Field
                key={field}
                isRequired={includes(field, requiredFields)}
                error={errors[field]}
                label={t(`domains:fields.${field}`)}
              >
                <InputComponent value={fields[field]} onChange={setStateFromEvent(setField(field, ''))} />
              </Field>
            )
          }
        })}
      </Box>
    </Modal>
  )
}

DomainForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  domain: DomainType,
}

export default DomainForm
