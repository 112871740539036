import { Box, Heading, Alert, AlertIcon, Grid, GridItem } from '@chakra-ui/react'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { withLoader } from '@Common/Utils/HOF'
import ServerInfo from '@Servers/Components/ServerInfo'
import ServerLastError from '@Servers/Components/ServerLastError'
import ServerStats from '@Servers/Components/ServerStats'
import ServerStatus from '@Servers/Components/ServerStatus'
import { useServerQuery } from '@Servers/Services/Api'
// import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

const ServerDetailView = () => {
  // const { t } = useTranslation()
  const params = useParams()
  const { data: server, isFetching } = useServerQuery(params.serverId)
  return (
    <BaseLayout>
      {withLoader(
        () => (
          <Box p="1.5rem">
            <Grid templateColumns="400px 1fr" gap={3}>
              <GridItem h="10">
                <Heading mb="1rem">{server.name}</Heading>
                <Alert status="info" mb="1rem">
                  <AlertIcon />
                  {server.description}
                </Alert>
                <ServerInfo server={server} />
              </GridItem>
              <GridItem>
                <ServerStatus server={server} />
                <ServerLastError server={server} />
                <ServerStats server={server} />
              </GridItem>
            </Grid>
          </Box>
        ),
        isFetching,
      )}
    </BaseLayout>
  )
}

export default ServerDetailView
