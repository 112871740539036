import { api } from '@Core/Services/Api';

const prefix = 'domain'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    domains: builder.query({
      query: () => ({
        url: `${prefix}`,
      }),
      providesTags: [{ type: 'Domain', id: 'LIST' }],
    }),
    domain: builder.query({
      query: (id) => ({
        url: `${prefix}/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'Domain', id: id }],
    }),
    createDomain: builder.mutation({
      query: (domain) => ({
        url: `${prefix}`,
        body: domain,
        method: 'POST',
      }),
      invalidatesTags: ['Domain'],
    }),
    updateDomain: builder.mutation({
      query: ({ id, domain }) => ({
        url: `${prefix}/${id}`,
        body: domain,
        method: 'PUT',
      }),
      invalidatesTags: ['Domain'],
    }),
    deleteDomain: builder.mutation({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Domain'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useDomainsQuery,
  useDomainQuery,
  useCreateDomainMutation,
  useUpdateDomainMutation,
  useDeleteDomainMutation,
} = extendedApi
