import PropTypes from 'prop-types'
import { Box, Center, Flex, Heading, Icon, Link, Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'
import { withLoader } from '@Common/Utils/HOF'
import { makePath } from '@Config'
import { DomainType } from '@Domains/Models/Domain'
import { defaultTo, pathEq } from 'ramda'
import { useTranslation } from 'react-i18next'
import { IoAlbumsOutline, IoAlertCircleOutline, IoCheckmarkCircleOutline } from 'react-icons/io5'
import { Link as RLink } from 'react-router-dom'
import DomainsRequestTimeChart from './DomainsRequestTimeChart'

const DomainsWidget = ({ domains, isFetching }) => {
  const { t } = useTranslation()

  return (
    <Box flexGrow={1}>
      <Flex direction="row">
        <Box p="1rem" flexGrow={2} background="blue.50">
          <Flex direction="row" justify="flex-end" p=".5rem .9rem">
            <Heading as="h2" size="lg">
              <Link as={RLink} to={makePath('domains.list')}>
                {t('domains:ui.Domains')}
              </Link>
            </Heading>
          </Flex>
          {withLoader(
            () => (
              <DomainsRequestTimeChart domains={domains} />
            ),
            isFetching,
          )}
        </Box>
        <Center p="1rem" background={'gray.200'} color="black">
          <StatGroup flexDirection="column">
            <Stat width="100%">
              <StatLabel fontSize={'1.4rem'} display="flex" alignItems={'center'} gap=".5rem">
                <IoAlbumsOutline />
                {t('ui.Total')}
              </StatLabel>
              <StatNumber textAlign={'center'} fontSize={'3rem'}>
                {defaultTo([], domains).length}
              </StatNumber>
            </Stat>
            <Stat width="100%">
              <StatLabel fontSize={'1.4rem'} display="flex" alignItems={'center'} gap=".5rem">
                <Icon as={IoCheckmarkCircleOutline} color="green.400" />
                {t('ui.Ok')}
              </StatLabel>
              <StatNumber textAlign={'center'} fontSize={'3rem'} color={'green.400'}>
                {defaultTo([], domains).filter(pathEq(['urlStatus', 'error'], false)).length}
              </StatNumber>
            </Stat>
            <Stat width="100%">
              <StatLabel fontSize={'1.4rem'} display="flex" alignItems={'center'} gap=".5rem">
                <Icon as={IoAlertCircleOutline} color="red" />
                {t('ui.Ko')}
              </StatLabel>
              <StatNumber textAlign={'center'} fontSize={'3rem'} color={'red.400'}>
                {defaultTo([], domains).filter(pathEq(['urlStatus', 'error'], true)).length}
              </StatNumber>
            </Stat>
          </StatGroup>
        </Center>
      </Flex>
    </Box>
  )
}

DomainsWidget.propTypes = {
  domains: PropTypes.arrayOf(DomainType),
  isFetching: PropTypes.bool,
}

export default DomainsWidget
