import { Alert, AlertIcon, Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { nanoSecondsToMilliSeconds } from '@Common/Utils/Datetime'
import { DomainType } from '@Domains/Models/Domain'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'

const DomainStatus = ({ domain }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Heading display="flex" justifyContent="space-between" mb="1rem">
        {t('domains:ui.LastStatus')}
        <Flex
          color={domain.urlStatus.error ? 'red' : 'green.400'}
          fontSize={'1.5rem'}
          alignItems="center"
          justifyContent="center"
          gap="1rem"
        >
          <IoCalendarOutline />
          <Text fontSize={'1rem'} pt={'.1rem'}>
            {dayjs(domain.urlStatus.updated * 1000).format('YYYY-MM-DD HH:mm:ss')}
          </Text>
        </Flex>
      </Heading>
      <Flex flexGrow={1} direction="column">
        <Alert status={domain.urlStatus.error ? 'error' : 'success'} mb="1rem">
          <AlertIcon />
          {domain.urlStatus.error ? domain.urlStatus.errorDescription : t('ui:Ok')}
        </Alert>
        {!domain.urlStatus.error && (
          <Table w={'100%'} mb='2rem'>
            <Thead>
              <Tr>
                <Th>{t('servers:ui.NameLookup')}</Th>
                <Th>{t('servers:ui.DNSLookup')}</Th>
                <Th>{t('servers:ui.TCPConnection')}</Th>
                <Th>{t('servers:ui.Connect')}</Th>
                <Th>{t('servers:ui.ServerProcessing')}</Th>
                <Th>{t('servers:ui.TLSHandshake')}</Th>
                <Th>{t('servers:ui.Pretransfer')}</Th>
                <Th>{t('servers:ui.StartTransfer')}</Th>
                <Th>{t('servers:ui.ContentTransfer')}</Th>
                <Th>{t('servers:ui.Total')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.nameLookup)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.dnsLookup)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.tcpConnection)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.connect)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.serverProcessing)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.tlsHandshake)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.preTransfer)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.startTransfer)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.contentTransfer)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(domain.urlStatus.total)} ms</Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Flex>
    </div>
  )
}

DomainStatus.propTypes = {
  domain: DomainType,
}

export default DomainStatus
