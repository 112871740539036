import { toast } from '@Common/Components/Toast'
import i18next from 'i18next'
import { T } from 'ramda'
import Logger from './Logger'

export const getRequestError = (error) => error // @TODO

export const responseTextError = (err) => err?.data?.message || i18next.t('errors.UnknownApiError')
export const isSuccessful = T // responses are not wrapped, error is catched in try block

export const request = async (endpoint, args, successText = null, errorTextId = null, unwrap = true) => {
  try {
    const response = unwrap ? await endpoint(...args).unwrap() : await endpoint(...args)
    if (isSuccessful(response)) {
      successText &&
        toast({
          title: i18next.t('ui.RequestSuccess'),
          description: successText,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      return { response, isSuccessful: true }
    } else {
      errorTextId &&
        toast({
          title: i18next.t('ui.RequestError'),
          description: i18next.t(errorTextId, { error: responseTextError(response) }),
          status: response?.statusCode === 409 ? 'warning' : 'error',
          duration: 3000,
          isClosable: true,
        })
      return { response, isSuccessful: false }
    }
  } catch (err) {
    Logger.error('Request error: ', err)
    errorTextId &&
      toast({
        title: i18next.t('ui.RequestError'),
        description: i18next.t(errorTextId, { error: responseTextError(err) }),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    return { response: await err.response, isSuccessful: false }
  }
}
