import React from 'react'
import { Route, Routes } from 'react-router-dom'
import config from '@Config'

import ServerDetailView from '@Servers/Views/ServerDetailView'
import ServerListView from '@Servers/Views/ServerListView'

/**
 * Servers section router
 */
export const ServersRouter = () => {
  return (
    <Routes>
      <Route path={config.urls.servers.detail} element={<ServerDetailView />} />
      <Route path={config.urls.servers.list} element={<ServerListView />} />
    </Routes>
  )
}

export default ServersRouter
