import { api } from '@Core/Services/Api';

const authPrefix = 'auth'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    whoami: builder.mutation({
      query: () => ({
        url: `${authPrefix}/whoami`,
        providesTags: ['AuthenticatedUser'],
      }),
    }),
    signIn: builder.mutation({
      query: (credentials) => ({
        url: `${authPrefix}/token/obtain`,
        method: 'POST',
        body: credentials,
      }),
    }),
    users: builder.query({
      query: () => ({
        url: `${authPrefix}/user`,
      }),
      providesTags: [{ type: 'User', id: 'LIST' }],
    }),
    createUser: builder.mutation({
      query: (user) => ({
        url: `${authPrefix}/user`,
        body: user,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: ({ id, user }) => ({
        url: `${authPrefix}/user/${id}`,
        body: user,
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useWhoamiMutation,
  useSignInMutation,
  useUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
} = extendedApi
