import { Alert, AlertIcon, Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { nanoSecondsToMilliSeconds } from '@Common/Utils/Datetime'
import { ServerType } from '@Servers/Models/Server'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'

const ServerStatus = ({ server }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Heading display="flex" justifyContent="space-between" mb="1rem">
        {t('servers:ui.LastStatus')}
        <Flex
          color={server.status.error ? 'red' : 'green.400'}
          fontSize={'1.5rem'}
          alignItems="center"
          justifyContent="center"
          gap="1rem"
        >
          <IoCalendarOutline />
          <Text fontSize={'1rem'} pt={'.1rem'}>
            {dayjs(server.status.updated * 1000).format('YYYY-MM-DD HH:mm:ss')}
          </Text>
        </Flex>
      </Heading>
      <Flex flexGrow={1} direction="column">
        <Alert status={server.status.error ? 'error' : 'success'} mb="1rem">
          <AlertIcon />
          {server.status.error ? server.status.errorDescription : t('ui:Ok')}
        </Alert>
        {!server.status.error && (
          <Table w={'100%'} mb='2rem'>
            <Thead>
              <Tr>
                <Th>{t('servers:ui.NameLookup')}</Th>
                <Th>{t('servers:ui.DNSLookup')}</Th>
                <Th>{t('servers:ui.TCPConnection')}</Th>
                <Th>{t('servers:ui.Connect')}</Th>
                <Th>{t('servers:ui.ServerProcessing')}</Th>
                <Th>{t('servers:ui.TLSHandshake')}</Th>
                <Th>{t('servers:ui.Pretransfer')}</Th>
                <Th>{t('servers:ui.StartTransfer')}</Th>
                <Th>{t('servers:ui.ContentTransfer')}</Th>
                <Th>{t('servers:ui.Total')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{nanoSecondsToMilliSeconds(server.status.nameLookup)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.dnsLookup)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.tcpConnection)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.connect)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.serverProcessing)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.tlsHandshake)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.preTransfer)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.startTransfer)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.contentTransfer)} ms</Td>
                <Td>{nanoSecondsToMilliSeconds(server.status.total)} ms</Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Flex>
    </div>
  )
}

ServerStatus.propTypes = {
  server: ServerType,
}

export default ServerStatus
