import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { DomainType } from '@Domains/Models/Domain'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import config from '@Config'
import { Box, Center, Heading } from '@chakra-ui/react'
import { groupBy, prop } from 'ramda'

const TechnologiesChart = ({ domains, isFetching }) => {
  const { t } = useTranslation()

  const groupedData = groupBy(prop('technology'), domains.filter(prop('technology')))
  const tot = domains.length

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: 'transparent',
      type: 'pie',
    },
    colors: config.ui.chartColors,
    credits: {
      enabled: false,
    },
    title: {
      style: { display: 'none' },
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          connectorColor: 'silver',
        },
      },
    },
    series: [
      {
        name: 'Share',
        data: Object.keys(groupedData).map(tech => ({
          name: tech,
          y: groupedData[tech].length * 100 / tot
        }))
      },
    ],
  }

  console.log(t, domains, isFetching) // eslint-disable-line

  return (
    <Box flexGrow={1}>
      <Center p="1rem" background={'gray.200'} color="black">
        <Heading as="h2" size="lg">
          {t('domains:ui.Technologies')}
        </Heading>
      </Center>
      <Box background="blue.50">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Box>
    </Box>
  )
}

TechnologiesChart.propTypes = {
  domains: PropTypes.arrayOf(DomainType),
  isFetching: PropTypes.bool,
}

export default TechnologiesChart
