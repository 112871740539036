/* eslint-disable react/no-children-prop */
import { DomainType } from '@Domains/Models/Domain'
import { Link as RLink } from 'react-router-dom'
import { List, ListIcon, ListItem, Link, Box } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { IoLinkOutline, IoPlanetOutline, IoServerOutline } from 'react-icons/io5'
import { makePath } from '@Config'

const DomainInfo = ({ domain }) => {
  const { t } = useTranslation()

  return (
    <div>
      <List>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={IoLinkOutline} />
          <Link as={'a'} target="_blank" href={domain.url}>
            {t('ui:Visit')}
          </Link>
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={IoPlanetOutline} />
          {domain.ip}
        </ListItem>
        {domain.server && (
          <ListItem display="flex" alignItems="center">
            <ListIcon as={IoServerOutline} />
            <Link as={RLink} to={makePath('servers.detail', { serverId: domain.server.id })}>
              {domain.server.name}
            </Link>
          </ListItem>
        )}
      </List>
      {domain.technology && (
        <p>
          <strong>{t('domains:fields.technology')}</strong>: {domain.technology}
        </p>
      )}
      {domain.framework && (
        <p>
          <strong>{t('domains:fields.framework')}</strong>: {domain.framework}
        </p>
      )}
      <p>
        <strong>{t('domains:fields.registrant')}</strong>: {domain.registrant}
      </p>
      <p>
        <strong>{t('domains:fields.registrar')}</strong>: {domain.registrar}
      </p>
      <Box pl="2rem">
        <ReactMarkdown children={domain.registrantInfo} />
      </Box>
      <p>
        <strong>{t('domains:fields.notes')}</strong>:
      </p>
      <Box pl="2rem">
        <ReactMarkdown children={domain.notes} />
      </Box>
    </div>
  )
}

DomainInfo.propTypes = {
  domain: DomainType,
}

export default DomainInfo
