import { logout } from '@Auth/Redux/Login'
import { Box, Heading, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import { redirectThunk } from '@Common/Utils/History'
import { useCurrentUser } from '@Common/Utils/Hooks'
import config from '@Config'
import { compose } from 'ramda'
import { useTranslation } from 'react-i18next'
import { IoLogOutOutline, IoPersonOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

const Navbar = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const dispatch = useDispatch()

  const handleSignOut = compose(
    dispatch,
    logout,
    redirectThunk(config.urls.login),
  )

  return (
    <Box color="white" display="flex" height="100%" alignItems="center" justifyContent="space-between" px={5}>
      <Heading fontSize={'1.6rem'}>{t('ui.AppTitle')}</Heading>
      <Box display="flex" gap={1}>
        <Menu>
          <MenuButton>
            <Box display="flex" alignItems='center' gap={2}>
              <IoPersonOutline />
              {user.email}
            </Box>
          </MenuButton>
          <Portal>
            <MenuList>
              <MenuItem cursor='pointer' onClick={handleSignOut}>
                <Box display="flex" gap="5" alignItems="center" justifyContent="space-between" width='100%'>
                  {t('auth:ui.SignOut')}
                  <IoLogOutOutline />
                </Box>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </Box>
  )
}

export default Navbar
