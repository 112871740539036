import { Route, Routes } from 'react-router-dom'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { history } from '@Core/Redux/Store'
import config from '@Config'
import { makePrivate } from '@Auth/Utils/Router'

// routers
import ServersRouter from '@Servers/Router'
import DomainsRouter from '@Domains/Router'

// views
import DashboardView from '@Dashboard/Views/DashboardView'
import SignInView from '@Auth/Views/SignInView'
import UsersListView from '@Auth/Views/UsersListView'

/**
 * Main application router
 * Each route element can be one of the following options:
 * - a view component
 * - a (sub)router defining other routes
 */
const AppRouter = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route path={config.urls.servers.base} element={makePrivate(<ServersRouter />)} />
        <Route path={config.urls.domains.base} element={makePrivate(<DomainsRouter />)} />
        <Route exact path={config.urls.users} element={makePrivate(<UsersListView />)} />
        <Route exact path={config.urls.home} element={makePrivate(<DashboardView />)} />
        <Route exact path={config.urls.login} element={<SignInView />} />
      </Routes>
    </Router>
  )
}

export default AppRouter
