import { nanoSecondsToMilliSeconds } from '@Common/Utils/Datetime'
import config from '@Config'
import { ServerType } from '@Servers/Models/Server'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import { compose, not, path, pick, values } from 'ramda'
import { useTranslation } from 'react-i18next'

const ServersRequestTimeChart = ({ servers }) => {
  const { t } = useTranslation()

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: '300px',
      zooming: {
        type: 'y'
      },
    },
    colors: config.ui.chartColors,
    credits: {
      enabled: false,
    },
    title: {
      text: t('servers:ui.RequestTime'),
      style: { display: 'none' },
    },
    xAxis: {
      categories: [
        t('servers:ui.Total'),
        t('servers:ui.DNSLookup'),
        t('servers:ui.TCPConnection'),
        t('servers:ui.Connect'),
      ],
      crosshair: true,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: 'Time (ms)',
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: servers.filter(compose(not, path(['status', 'error']))).map((server) => ({
      name: server.name,
      data: values(pick(['total', 'dnsLookup', 'tcpConnection', 'connect'], server.status)).map(nanoSecondsToMilliSeconds),
    })),
  }
  console.log('OPTIONS', options) // eslint-disable-line
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

ServersRequestTimeChart.propTypes = {
  servers: PropTypes.arrayOf(ServerType),
}

export default ServersRequestTimeChart
