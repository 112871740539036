import Logo from '@Assets/Images/log8.jpg'
import Bg from '@Assets/Images/startup-bg.jpg'
import SignInForm from '@Auth/Forms/SignInForm'
import { useAuthentication } from '@Auth/Hooks'
import { setAccessToken } from '@Auth/Redux/Login'
import { useSignInMutation } from '@Auth/Services/Api'
import { Center, Flex, Heading, Image, useToast } from '@chakra-ui/react'
import storageFactory from '@Common/Services/Storage'
import { responseTextError } from '@Common/Utils/Api'
import { useCurrentUser } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import config from '@Config'
import { history } from '@Core/Redux/Store'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

const Storage = storageFactory()

const Wrapper = styled(Flex)`
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  color: #fff;
  min-height: 100vh;
`
const SignInView = () => {
  const dispatch = useDispatch()
  const user = useCurrentUser()
  const { t } = useTranslation()
  const toast = useToast()
  const [signIn] = useSignInMutation()
  const [loginSuccess, setLoginSuccess] = useState(false)
  const { isComplete } = useAuthentication(loginSuccess, [loginSuccess])

  useEffect(() => {
    if (isComplete) history.push(config.urls.home)
    if (user) history.push(config.urls.home)
  }, [isComplete, user])

  const handleSubmit = async ({ email, password }) => {
    try {
      const res = await signIn({ email, password }).unwrap()
      dispatch(setAccessToken(res))
      Storage.save('refreshToken', res.refreshToken)
      setLoginSuccess(true) // trigger whoami with useAuthentication
    } catch (err) {
      Logger.error('Signin error: ', err)
      toast({
        title: t('auth:ui.SignInError'),
        description: responseTextError(err),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Wrapper align="center" direction="column" justify="center">
      <Center flexDir='column' m="2rem" p="2rem" gap="1rem">
        <Image src={Logo} alt="logo" />
        <Heading>{t('ui.AppTitle')}</Heading>
        <SignInForm onSubmit={handleSubmit} />
      </Center>
    </Wrapper>
  )
}

export default SignInView
