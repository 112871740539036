import PropTypes from 'prop-types'
import { Link, Box, Flex, Heading, IconButton, Table, Tbody, Td, Th, Thead, Tr, Icon, Tooltip } from '@chakra-ui/react'
import { toast } from '@Common/Components/Toast'
import { responseTextError } from '@Common/Utils/Api'
import Logger from '@Common/Utils/Logger'
import { makePath } from '@Config'
import ServerForm from '@Servers/Forms/ServerForm'
import { useCreateServerMutation, useServersQuery, useUpdateServerMutation } from '@Servers/Services/Api'
import { defaultTo } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddOutline, IoAlertCircleOutline, IoCheckmarkCircleOutline, IoPencilOutline } from 'react-icons/io5'
import { Link as ReactRouterLink } from 'react-router-dom'
import BaseLayout from '@Common/Layouts/BaseLayout'

const ServerListView = () => {
  const { t } = useTranslation()
  const [createServer] = useCreateServerMutation()
  const [updateServer] = useUpdateServerMutation()

  const [form, setForm] = useState({ isOpen: false, server: null })
  const handleAddServer = () => setForm({ isOpen: true, server: null })
  const handleUpdateServer = (server) => () => setForm({ isOpen: true, server })
  const handleCloseForm = () => setForm({ isOpen: false, server: null })

  const { data } = useServersQuery()

  const handleSubmit = async (fields) => {
    const update = form.server?.id
    try {
      update ? await updateServer({ id: form.server.id, server: fields }).unwrap() : await createServer(fields).unwrap()
      toast({
        title: t(`servers:ui.${update ? 'Edit' : 'Add'}Server`),
        description: t('ui.OperationSuccessfull'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      Logger.error(`${update ? 'Update' : 'Create'} server error`, err)
      toast({
        title: t(`servers:ui.${update ? 'Edit' : 'Add'}Server`),
        description: t('ui.OperationError', { error: responseTextError(err) }),
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    }
    handleCloseForm()
  }

  return (
    <BaseLayout>
      <Box p="2rem 1rem 1rem">
        <Flex direction="row" justify="space-between" p="0 .9rem 1rem">
          <Heading async="h2" size="lg">
            {t('servers:ui.Servers')}
          </Heading>
          <IconButton isRound onClick={handleAddServer}>
            <IoAddOutline />
          </IconButton>
        </Flex>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t('servers:fields.name')}</Th>
              <Th>{t('servers:fields.ip')}</Th>
              <Th>{t('servers:fields.os')}</Th>
              <Th>{t('servers:fields.kernel')}</Th>
              <Th>{t('servers:fields.cpu')}</Th>
              <Th>{t('servers:fields.ram')}</Th>
              <Th>{t('servers:fields.status')}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {defaultTo([], data).map((server) => (
              <Tr key={server.id}>
                <Td>
                  <Link as={ReactRouterLink} color="red" to={makePath('servers.detail', { serverId: server.id })}>
                    {server.name}
                  </Link>
                </Td>
                <Td>{server.ip}</Td>
                <Td>{server.os}</Td>
                <Td>{server.kernel}</Td>
                <Td>{server.cpu}</Td>
                <Td>{server.ram}</Td>
                <Td>
                  {server.status.error ? (
                    <Tooltip label={server.status.errorDescription}>
                      <span>
                        <Icon as={IoAlertCircleOutline} color="red" w={8} h={8} />
                      </span>
                    </Tooltip>
                  ) : (
                    <Icon as={IoCheckmarkCircleOutline} color="green.400" w={8} h={8} />
                  )}
                </Td>
                <Td textAlign="end">
                  <IconButton isRound onClick={handleUpdateServer(server)}>
                    <IoPencilOutline />
                  </IconButton>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {form.isOpen && <ServerForm server={form.server} onClose={handleCloseForm} onSubmit={handleSubmit} />}
      </Box>
    </BaseLayout>
  )
}

ServerListView.defaultProps = {
  listDisplay: ['name', 'ip', 'os', 'kernel', 'cpu', 'ram', 'status'],
}

ServerListView.propTypes = {
  listDisplay: PropTypes.array,
}

export default ServerListView
