import { nanoSecondsToMilliSeconds } from '@Common/Utils/Datetime'
import config from '@Config'
import { DomainType } from '@Domains/Models/Domain'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import { both, compose, not, path, pick, prop, values } from 'ramda'
import { useTranslation } from 'react-i18next'

const DomainsRequestTimeChart = ({ domains }) => {
  const { t } = useTranslation()

  const options = {
    chart: {
      type: 'column',
      backgroundColor: '#F7FAFC',
      height: '300px',
      zooming: {
        type: 'y'
      }
    },
    colors: config.ui.chartColors,
    credits: {
      enabled: false,
    },
    title: {
      text: t('servers:ui.RequestTime'),
      style: { display: 'none' },
    },
    xAxis: {
      categories: [
        t('servers:ui.Total'),
        t('servers:ui.DNSLookup'),
        t('servers:ui.ServerProcessing'),
        t('servers:ui.ContentTransfer'),
      ],
      crosshair: true,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: 'Time (ms)',
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      outside: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: domains.filter(both(compose(not, path(['urlStatus', 'error'])), prop('includeInDashboardChart'))).map((domain) => ({
      name: domain.name,
      data: values(pick(['total', 'dnsLookup', 'serverProcessing', 'contentTransfer'], domain.urlStatus)).map(nanoSecondsToMilliSeconds),
    })),
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

DomainsRequestTimeChart.propTypes = {
  domains: PropTypes.arrayOf(DomainType),
}

export default DomainsRequestTimeChart
