import { ServerType } from '@Servers/Models/Server'
import { List, ListIcon, ListItem, Link } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoGlobeOutline, IoPlanetOutline } from 'react-icons/io5'

const ServerInfo = ({ server }) => {
  const { t } = useTranslation()

  return (
    <div>
      <List>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={IoPlanetOutline} />
          {server.ip}
        </ListItem>
        {server.domain && (
          <ListItem display="flex" alignItems="center">
            <ListIcon as={IoGlobeOutline} />
            <Link target="_blank" href={`http://${server.domain}`}>
              {server.domain}
            </Link>
          </ListItem>
        )}
      </List>
      <p>
        <strong>{t('servers:fields.cpu')}</strong>: {server.cpu}
      </p>
      <p>
        <strong>{t('servers:fields.ram')}</strong>: {server.ram}
      </p>
      <p>
        <strong>{t('servers:fields.os')}</strong>: {server.os}
      </p>
      <p>
        <strong>{t('servers:fields.kernel')}</strong>: {server.kernel}
      </p>
      <p>
        <strong>{t('servers:fields.healthCheckUrl')}</strong>: <Link as='a' href={server.healthCheckUrl} target='_blank'>{server.healthCheckUrl}</Link>
      </p>
    </div>
  )
}

ServerInfo.propTypes = {
  server: ServerType,
}

export default ServerInfo
