import { Box, Heading, Grid, GridItem } from '@chakra-ui/react'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { withLoader } from '@Common/Utils/HOF'
import config from '@Config'
import DomainInfo from '@Domains/Components/DomainInfo'
import DomainStatus from '@Domains/Components/DomainStatus'
import { useDomainQuery } from '@Domains/Services/Api'
// import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

const DomainDetailView = () => {
  // const { t } = useTranslation()
  const params = useParams()
  const { data: domain, isFetching } = useDomainQuery(params.domainId)

  return (
    <BaseLayout>
      {withLoader(
        () => (
          <Box p="1.5rem">
            <Grid templateColumns="400px 1fr" gap={3}>
              <GridItem>
                <Heading mb="1rem">{domain.name}</Heading>
                <div
                  style={{
                    background: `url(${config.mediaBasePath + domain.screenshot}) no-repeat top left`,
                    backgroundSize: '100%',
                    width: '100%',
                    height: '200px',
                    marginBottom: '1rem',
                  }}
                />
                <DomainInfo domain={domain} />
              </GridItem>
              <GridItem>
                <DomainStatus domain={domain} />
              </GridItem>
            </Grid>
          </Box>
        ),
        isFetching,
      )}
    </BaseLayout>
  )
}

export default DomainDetailView
