import { Alert, AlertIcon, Flex, Heading, Text } from '@chakra-ui/react'
import { ServerType } from '@Servers/Models/Server'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'

const ServerLastError = ({ server }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Heading display="flex" justifyContent="space-between" mb="1rem">
        {t('servers:ui.LastError')}
        {server.lastError && (
          <Flex color={'red'} fontSize={'1.5rem'} alignItems="center" justifyContent="center" gap="1rem">
            <IoCalendarOutline />
            <Text fontSize={'1rem'} pt={'.1rem'}>{dayjs(server.lastError.updated * 1000).format('YYYY-MM-DD HH:mm:ss')}</Text>
          </Flex>
        )}
      </Heading>
      <Flex flexGrow={1} direction="column" mb='1rem'>
        <Alert status={server.lastError ? 'error' : 'success'} mb="1rem">
          <AlertIcon />
          {server.lastError ? server.lastError.errorDescription : t('servers:ui:NoServerErrorEvents')}
        </Alert>
      </Flex>
    </div>
  )
}

ServerLastError.propTypes = {
  server: ServerType,
}

export default ServerLastError
