import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { IoLockClosedOutline, IoPersonOutline } from 'react-icons/io5'
import { useForm } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import { checkRequired } from '@Common/Utils/Validation'
import { always, compose, ifElse, isEmpty, pipe, prop, tap } from 'ramda'

export const validate = (fields) => {
  const errors = {}
  checkRequired(errors, ['email', 'password'], fields)
  return { success: isEmpty(errors), errors }
}

const SignInForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  const { fields, setField, errors, setErrors, clearErrors } = useForm({ email: '', password: '' })

  const handleSubmit = pipe(
    always(fields),
    validate,
    ifElse(
      prop('success'),
      compose(onSubmit, always(fields), tap(clearErrors)),
      compose(setErrors, prop('errors'))
    ),
  )

  return (
    <Flex direction="column" gap="1rem" align="center">
      <FormControl isRequired isInvalid={errors.email}>
        <FormLabel>{t('auth:fields.email')}</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <IoPersonOutline />
          </InputLeftElement>
          <Input
            type="text"
            name="username"
            value={fields.email}
            onChange={setStateFromEvent(setField('email', ''))}
          />
        </InputGroup>
        <FormErrorMessage>{t(errors.email)}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={errors.password}>
        <FormLabel>{t('auth:fields.password')}</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <IoLockClosedOutline />
          </InputLeftElement>
          <Input
            type="password"
            name="password"
            value={fields.password}
            onChange={setStateFromEvent(setField('password', ''))}
          />
        </InputGroup>
        <FormErrorMessage>{t(errors.password)}</FormErrorMessage>
      </FormControl>
      <Button colorScheme="red" onClick={handleSubmit}>
        {t('auth:ui.SignIn')}
      </Button>
    </Flex>
  )
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SignInForm
