import UserForm from '@Auth/Forms/UserForm'
import { useCreateUserMutation, useUpdateUserMutation, useUsersQuery } from '@Auth/Services/Api'
import { Box, Flex, Heading, IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { toast } from '@Common/Components/Toast'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { responseTextError } from '@Common/Utils/Api'
import Logger from '@Common/Utils/Logger'
import dayjs from 'dayjs'
import { defaultTo } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddOutline, IoPencilOutline } from 'react-icons/io5'

const UsersListView = () => {
  const { t } = useTranslation()
  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()

  const [form, setForm] = useState({ isOpen: false, server: null })

  const handleAddUser = () => setForm({ isOpen: true, user: null })
  const handleUpdateUser = (user) => () => setForm({ isOpen: true, user })
  const handleCloseForm = () => setForm({ isOpen: false, user: null })

  const { data } = useUsersQuery()

  const handleSubmit = async (fields) => {
    const update = form.user?.id
    try {
      update ? await updateUser({ id: form.user.id, user: fields }).unwrap() : await createUser(fields).unwrap()
      toast({
        title: t(`auth:ui.${update ? 'Edit' : 'Add'}User`),
        description: t('ui.OperationSuccessfull'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      Logger.error(`${update ? 'Update' : 'Create'} user error`, err)
      toast({
        title: t(`auth:ui.${update ? 'Edit' : 'Add'}User`),
        description: t('ui.OperationError', { error: responseTextError(err) }),
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
    }
    handleCloseForm()
  }

  return (
    <BaseLayout>
      <Box p="2rem 1rem 1rem">
        <Flex direction="row" justify="space-between" p="0 .9rem 1rem">
          <Heading async="h2" size="lg">
            {t('auth:ui.Users')}
          </Heading>
          <IconButton isRound onClick={handleAddUser}>
            <IoAddOutline />
          </IconButton>
        </Flex>

        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t('auth:fields.id')}</Th>
              <Th>{t('auth:fields.email')}</Th>
              <Th>{t('auth:fields.role')}</Th>
              <Th>{t('auth:fields.created')}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {defaultTo([], data).map((user) => (
              <Tr key={user.id}>
                <Td>{user.id}</Td>
                <Td>{user.email}</Td>
                <Td>{user.role}</Td>
                <Td>{dayjs(user.created * 1e3).format('YYYY-MM-DD HH:mm:ss')}</Td>
                <Td textAlign="end">
                  <IconButton isRound onClick={handleUpdateUser(user)}>
                    <IoPencilOutline />
                  </IconButton>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {form.isOpen && <UserForm user={form.user} onClose={handleCloseForm} onSubmit={handleSubmit} />}
    </BaseLayout>
  )
}

export default UsersListView
